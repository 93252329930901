import {defineStore} from 'pinia';
import {getItem, removeItem, setItem} from '@/utility/localStorageControl';

export default defineStore("user", {
  state: () => ({
    userLoggedIn: false,
    isAreaManager: false,
    isCountyManager: false,
    isStoreManager: false,
    isArredatore: false,
    isKpiLocked: false,
    currency: 'EURO'

  }),
  actions: {
    async authenticate(user: any) {
      setItem("user", user);
      this.userLoggedIn = true;
      if (user.userrole){
        switch(user.userrole.developername) {
          case 'AreaManager': {
            this.isAreaManager = true;
            break;
          }
          case 'StoreManager': {
            this.isStoreManager = true
            break;
          }
          case 'CountryManager': {
            this.isCountyManager = true
            break;
          }
          case 'Arredatore': {
            this.isArredatore = true
            break;
          }
          default: {
            this.isArredatore = true
            break;
          }
        }
      }
    },
    async signOut() {
      const user = getItem("user");
      if (user) {
        removeItem("user");
        removeItem("shop");
      }
      this.userLoggedIn = false;
      this.isAreaManager = false;
      this.isCountyManager = false;
      this.isStoreManager = false;
      this.isArredatore = false;
      this.isKpiLocked = false

    },
    async autoRegister() {
      const user = getItem("user");
      if (user) {
        this.userLoggedIn = true;
        if (user.userrole) {
          switch (user.userrole.developername) {
            case 'AreaManager': {
              this.isAreaManager = true;
              break;
            }
            case 'StoreManager': {
              this.isStoreManager = true
              break;
            }
            case 'CountryManager': {
              this.isCountyManager = true
              break;
            }
            case 'Arredatore': {
              this.isArredatore = true
              break;
            }
            default: {
              this.isArredatore = true
              break;
            }
          }
        }
      }
    },
    async setKpiLocked(val: boolean){
      this.isKpiLocked = val;
    },
    async setCurrency(currency: string){
      this.currency = currency;
    },
    async getCurrency(){
      return this.currency;
    }
  }
});
