import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  RouteRecordRaw,
} from "vue-router";
import index from "@/views/Index.vue";
import useUserStore from "@/stores/user";
import PersonanAccountList from "@/components/personAccount/personAccountList.vue";

const routes = [
  {
    path: "/",
    name: "index",
    component: index,
  },
  {
    path: "/login",
    name: "login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../components/login/login.vue"),
  },
  {
    path: "/shopselect/:cityNames",
    props: true,
    name: "shopselect",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/shopselect/shop_select.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/dashboard/:error?",
    name: "dashboard",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/dashboard/dashboard.vue"
      ),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/personAccountList/:searchString?",
    name: "personaccountlist",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/personAccount/personAccountList.vue"
      ),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/personAccountShow/:isTickets?",
    name: "personaccountshow",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/personAccount/personAccountView.vue"
      ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/personAccountAddEdit/:action?",
    name: "personaccountaddedit",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/personAccount/personaAccountAdd.vue"
      ),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/quotations/:use_data?",
    name: "quotations",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(
            /* webpackChunkName: "about" */ "../components/quotation/quotationsList.vue"
            ),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/orders/:use_data?",
    name: "orders",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(
            /* webpackChunkName: "about" */ "../components/order/ordersList.vue"
            ),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/tasks/:use_data?",
    name: "tasks",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../components/task/tasksList.vue"
      ),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/kpis",
    name: "kpis",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
        import(
            /* webpackChunkName: "about" */ "../components/kpi/kpi.vue"
            ),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/:catchAll(.*)*",
    redirect: { name: "login" },
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  // history: createMemoryHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log("Global Guard");

  if (!to.meta.requiresAuth) {
    next();
    return;
  }

  const store = useUserStore();

  if (store.userLoggedIn) {
    next();
  } else {
    next({ name: "login" });
  }
});
export default router;
