<template>
  <Toast />
  <div class="container-fluid">
      <router-view/>
  </div>
</template>

<style>
.v-toast__item--error {
  background-color: #D51317 !important;
}

.v-toast__item--success {
  background-color: #243545 !important;
}

</style>
<script>
import update from './mixins/update'

export default {
  name: 'App',
  mixins: [update]
}

</script>
