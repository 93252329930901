import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import VeeValidatePlugin from "./includes/validation"
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import "../src/assets/css/custom.css"
import "../src/assets/css/app_ps.css"
import "../src/assets/css/metropolis_font/stylesheet.css"
import 'vue-toast-notification/dist/theme-sugar.css';
import {createI18n} from 'vue-i18n';
import {createPinia} from 'pinia';
import VueApexCharts from "vue3-apexcharts";
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import 'vue-final-modal/style.css';

// import the package
import VueAwesomePaginate from "vue-awesome-paginate";

// import the necessary css file
import "vue-awesome-paginate/dist/style.css";
import Vue3TouchEvents from "vue3-touch-events";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';
/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

/* add icons to the library */
library.add(fas, far, fab);

//Import locales
import it from './locales/it.json';
import en from './locales/en-US.json';
import de from './locales/de-DE.json';
import ToastPlugin from 'vue-toast-notification';
import {setLocale} from '@vee-validate/i18n';
import {createVfm} from 'vue-final-modal';
import 'vue-final-modal/style.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import moment from 'moment';

const i18n = createI18n({
    locale: "it",
    fallbackLocale: "it",
    messages: { it, en, de },
});

const app = createApp(App);

const PORT = process.env.PORT || 5000

const vfm = createVfm();
/* GLOBAL VARIABLES*/
app.config.globalProperties.$endpoint = process.env.VUE_APP_END_POINT_URL
app.config.globalProperties.$moment=moment

app.use(Vue3TouchEvents);
app.use(i18n);
app.use(createPinia());
app.use(VeeValidatePlugin);
app.use(router);
app.use(VueApexCharts);
app.use(ToastPlugin);
app.use(LoadingPlugin);
app.use(vfm);
app.use(VueAwesomePaginate);
app.component('VueDatePicker', VueDatePicker);
app.component('font-awesome-icon', FontAwesomeIcon);
setLocale('it');

app.mount('#app');
