<template>
  <div class="row d-flex justify-content-center align-items-center height-full-screen">
    <div class="col-4">
      <router-link to="/login"><img class="img-full" src="../assets/images/logo-poltrone-sofa.svg"></router-link>
<!--      <a href="#"></a>-->
    </div>
  </div>
</template>

<script>
import useUserStore from "@/stores/user";
import router from "@/router";
export default {
  name: "appIndexPage",
  mounted () {
    document.body.classList.add('blu', 'appIndexPage');
    const store = useUserStore();
    store.autoRegister();

    if (store.userLoggedIn) {
      router.push('/dashboard')
    } else {
      router.push('/login')
    }
  },
  unmounted () {
    document.body.classList.remove('blu', 'appIndexPage')
  },
}
</script>
