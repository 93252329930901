import {configure, defineRule, ErrorMessage, Field, Form,} from 'vee-validate';
import {
    alpha_spaces as alphaSpaces,
    confirmed,
    email,
    max,
    max_value as maxVal,
    min,
    min_value as minVal,
    not_one_of as excluded, numeric,
    required,
} from '@vee-validate/rules';
import {localize} from '@vee-validate/i18n';

interface Dictionary<Type> {
    [key: string]: Type;
}

export default {
    install: function (app: any) {
        app.component("VeeForm", Form);
        app.component("VeeField", Field);
        app.component("ErrorMessage", ErrorMessage);

        defineRule("required", required);
        defineRule("tos", required);
        defineRule("min", min);
        defineRule("max", max);
        defineRule("alpha_spaces", alphaSpaces);
        defineRule("email", email);
        defineRule("min_value", minVal);
        defineRule("max_value", maxVal);
        defineRule("passwords_mismatch", confirmed);
        defineRule("excluded", excluded);
        defineRule("country_excluded", excluded);
        defineRule("numeric", numeric);

        configure({
/*
            generateMessage: (ctx) => {
                const messages:Dictionary<string> = {
                    required: `The field ${ctx.field} is required.`,
                    min: `The field ${ctx.field} is too short.`,
                    max: `The field ${ctx.field} is too long.`,
                    alpha_spaces: `The field ${ctx.field} may only contain alphabetical characters and spaces.`,
                    email: `The field ${ctx.field} must be a valid email.`,
                    min_value: `The field ${ctx.field} is too low.`,
                    max_value: `The field ${ctx.field} is too high.`,
                    excluded: `You are not allowed to use this value for the field ${ctx.field}.`,
                    country_excluded:
                        "Due to restrictions, we do not accept users from this location.",
                    passwords_mismatch: "The passwords don't match.",
                    tos: "You must accept the Terms of Service.",
                };

                return messages[ctx.rule!.name] ? messages[ctx.rule!.name] : `The field ${ctx.field} is invalid.`;
            },
*/
            generateMessage: localize({
                it: {
                    messages: {
                        required: 'Questo campo è obbligatorio',
                        min: `Il campo è troppo corto.`,
                        max: `Il campo è troppo lungo.`,
                        alpha_spaces: `Il campo può contenere solo caratteri e spazi non numeri.`,
                        email: `Il campo deve essere una mail formalmente valida.`,
                        min_value: `Il valore del campo è troppo basso.`,
                        max_value: `Il valore del campo è troppo alto.`,
                        excluded: `Non è possibile usare questo valore nel campo.`,
                        numeric: 'Si possono inserire solo numeri'
                    },
                },
                en: {
                    messages: {
                        required: 'Questo campo è richiesto',
                        min: `Il campo è troppo corto.`,
                        max: `Il campo è troppo lungo.`,
                        alpha_spaces: `Il campo può contenere solo caratteri e spazi non numeri.`,
                        email: `Il campo deve essere una mail formalmente valida.`,
                        min_value: `Il valore del campo è troppo basso.`,
                        max_value: `Il valore del campo è troppo alto.`,
                        excluded: `Non è possibile usare questo valore nel campo.`,
                        numeric: 'Only numeric values are allowed'
                    },
                },
            }),
            validateOnBlur: true,
            validateOnChange: true,
            validateOnInput: false,
            validateOnModelUpdate: true,
        });
    },

}
